<template>
  <div>
    <h1>Import recipients</h1>

    <form @submit="submitForm" class="w-full lg:w-1/2 my-4 p-6 bg-white rounded shadow-lg">
      <div>
        <textarea v-model="receivers" class="h-96 block w-full px-3 py-2 text-black placeholder-gray-400 bg-white border border-gray-300 rounded shadow-sm" spellcheck="off" placeholder="Paste list of recipients into this field. One line per user: [username];[fullname]" />
      </div>
      <div class="grid grid-cols-2 mt-6">
        <div class="justify-self-start">
          <button class="pt-1 pr-3 pb-1 pl-3 h-10 text-white font-light tracking-wider bg-gray-500 rounded">
            Import recipients
          </button>
        </div>
        <div class="justify-self-end text-right pt-2" v-if="importState !== null">
          {{ importState }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['projectId'],

  data() {
    return {
      imported: 0,
      receivers: null,
      importState: null,
    }
  },
  methods: {
    async submitForm(evt) {
      
      this.imported = 0;
      
      evt.preventDefault();
      evt.submitter.disabled = true;
      evt.submitter.innerText = 'Please be patient...';

      if (this.receivers != null) 
      {
        this.importState = 'Preparing import... please wait'

        const chunkSize = 500;
        const receivers = this.receivers.split('\n').filter(function(e) { return e });
        
        const chunks = Array(
          Math.ceil(receivers.length / chunkSize))
          .fill()
          .map((_, index) => index * chunkSize)
          .map(begin => receivers.slice(begin, begin + chunkSize)
        );

        for (const [index, chunk] of chunks.entries()) {
          await new Promise(resolve => {

            axios.post(`${process.env.VUE_APP_API_URL}/projects/recipients/${this.projectId}/import?chunk=${index}`, {
              list: chunk
            }).then(response => {
              this.imported += response.data.count;
              this.importState = `${this.imported.toLocaleString('da-DK')} of ${receivers.length.toLocaleString('da-DK')} recipients imported (${Math.floor((this.imported / receivers.length) * 100)}%)`;
              resolve();
            }).catch(error => {
              console.log(error)
              this.importState = error;
            });

          });
        }

        evt.submitter.disabled = false;
        evt.submitter.innerText = 'Import recipients';
      }
    }
  }
}
</script>
